import React from 'react';
import {
  Box,
  Stack,
  HStack,
  Text,
  Heading,
  Divider,
  List,
  ListIcon,
  ListItem,
  Button
} from "@chakra-ui/react";
import { ArrowForwardIcon, CheckCircleIcon } from "@chakra-ui/icons";

import { usePaddleCheckout } from '../../../hooks/usePaddleCheckout';
import { themeColorHex } from '../../../constants';

const PricingBox = ({
  popular,
  name,
  price,
  currency,
  info = '',
  id,
  features = [],
  closePlans,
  interval
}) => {
  const { openCheckout } = usePaddleCheckout();
  return (
    <Stack
      spacing={2}
      border="3px solid"
      borderColor={popular ? themeColorHex : 'gray.300'}
      borderRadius="0.7rem"
      p={4}
      mt={10}
      h="350px"
      backgroundColor="white"
      position="relative"
    >
      {popular && (
        <Box
          position="absolute"
          top="0"
          right="0"
          backgroundColor={themeColorHex}
          color="white"
          paddingX={2}
          paddingY={1}
          borderRadius="0 0.4rem 0 0.7rem"
          fontSize="0.8rem"
        >
          POPULAR
        </Box>
      )}
      <Text textTransform="uppercase">{name}</Text>
      <HStack>
        <Heading>{price > 0 ? `${currency} ${price}` : 'Free'}</Heading>
        {price && (
          <Box as="span" color="gray.600" fontSize="xs">
            / {interval}
          </Box>
        )}
      </HStack>
      <Divider borderColor="blackAlpha.500" />
      <List flex="1">
        {features.map((feature) => (
          <ListItem key={Math.random()} fontSize="sm" display="flex">
            <ListIcon as={CheckCircleIcon} color="green.500" mt={1} />
            {feature}
          </ListItem>
        ))}
      </List>
      <Box>
        <Button
          variant="solid"
          size="md"
          width="100%"
          rightIcon={<ArrowForwardIcon />}
          borderRadius={10}
          display={price > 0 ? 'flex' : 'none'}
          justifyContent="space-between"
          backgroundColor={themeColorHex}
          _hover={{
            backgroundColor: 'blue.500'
          }}
          onClick={() => {
            openCheckout({ priceId: id });
            closePlans();
          }}
          color="white"
          id={ "priceBox-" + id }
        >
          Buy Now
        </Button>
        <Text mt={2} fontSize="xs">
          {info}
        </Text>
      </Box>
    </Stack>
  );
};

export default PricingBox;
